.chat-container {
  position: relative;
  background-color: transparent;
}

.chat-container::before {
  content: '';
  display: block;
  position: fixed;
  top: 78px;
  left: 50%;
  transform: translateX(-50%);
  width: 870px;
  height: calc(100vh - 12rem - 64px);
  background-image: url('/public/images/three_bees.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.10;
  z-index: 0;
  pointer-events: none;
}

.intro-dialog {
  position: relative;
  background-color: transparent;
}

.intro-dialog::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/three_bees.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.15;
  z-index: 0;
  pointer-events: none;
}

.auth-form-container {
  position: relative;
  background-color: transparent;
}

.auth-form-container::before {
  content: '';
  display: block;
  position: fixed;
  top: 78px;
  left: 50%;
  transform: translateX(-50%);
  width: 870px;
  height: calc(100vh - 12rem - 64px);
  background-image: url('/public/images/three_bees.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.10;
  z-index: 0;
  pointer-events: none;
}
